@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,400;0,500;0,600;0,700;0,900;1,400;1,900&display=swap");
@import-normalize;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
    width: 900px;
    margin: 0 auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

h1 {
    font-size: 4rem;

    @media screen and (max-width: 1024px) {
        font-size: 5rem;
    }
}

h2 {
    font-size: 3rem;

    @media screen and (max-width: 1024px) {
        font-size: 4.1rem;
    }
}

h3 {
    font-size: 2rem;
}

h4 {
    font-size: 1.8rem;
}

label {
    @media screen and (max-width: 1024px) {
        font-size: 1.2rem !important;
    }
}

input,
p,
span {
    @media screen and (max-width: 1024px) {
        font-size: 1.3rem !important;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
    font-family: "Fira Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    color: white;
}

a {
    color: #78a8f5;
}

.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.897) !important;
}
